import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useLockBodyScroll, useToggle } from 'react-use';

import './header.module.scss';

const HamburgerIcon = ({ toggle, open }) => {
  useLockBodyScroll(open);

  return (
    <button
      onClick={toggle}
      styleName={`hamburger ${!open ? '' : 'hamburger--is-open'}`}
      aria-label="Menu Button"
    >
      <div styleName="hamburger__bar hamburger__bar--top" />
      <div styleName="hamburger__bar hamburger__bar--middle" />
      <div styleName="hamburger__bar hamburger__bar--bottom" />
    </button>
  );
};

const MobileMenu = ({ toggle, open, navItems }) => {
  return (
    <div styleName={`mobile-menu ${open ? 'mobile-menu--open' : ''}`}>
      <HamburgerIcon toggle={toggle} open={open} />

      <ul styleName="mobile-menu__menu">
        <li>
          <Link to="/" activeStyleName="active">
            Home
          </Link>
        </li>
        {navItems.map((item, i) => (
          <li key={i}>
            <Link to={`/${item.primary.link.uid}`} activeStyleName="active">
              {item.primary.link_text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicLayout {
        edges {
          node {
            data {
              logo {
                url
              }
              header_menu {
                ... on PrismicLayoutHeaderMenuMenuItem {
                  primary {
                    link_text
                    link {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [open, toggle] = useToggle(false);

  const logo = data.allPrismicLayout.edges[0].node.data.logo;
  const navItems = data.allPrismicLayout.edges[0].node.data.header_menu;

  return (
    <>
      <header className="o-container-fluid" styleName="header">
        <Link to="/" styleName="header__logo">
          <img src={logo.url} alt={siteTitle} />
        </Link>

        <HamburgerIcon toggle={toggle} open={open} />

        <ul styleName="header__menu">
          {navItems.map((item, i) => (
            <li styleName="header__menu-item" key={i}>
              <Link
                to={`/${item.primary.link.uid}`}
                styleName="header__menu-link"
                activeStyleName="active"
              >
                {item.primary.link_text}
              </Link>
            </li>
          ))}
        </ul>
      </header>

      <MobileMenu open={open} navItems={navItems} toggle={toggle} />
    </>
  );
};

export default Header;
