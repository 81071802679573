import React from 'react';

import './gradient-bg.module.scss';

const GradientBG = ({ hideGradient }) => {
  return (
    <div styleName={`gradient ${hideGradient ? 'gradient--small' : ''}`} />
  );
};

export default GradientBG;
