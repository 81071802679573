import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import './footer.module.scss';

import logo from '../../images/argon-logo--element.jpg';
import linkedIn from '../../images/linkedin.svg';
import twitter from '../../images/twitter.svg';
import email from '../../images/email.svg';
import craftLogo from '../../images/craft-logo.svg';

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicLayout {
        edges {
          node {
            data {
              footer_menu {
                ... on PrismicLayoutFooterMenuMenuItem {
                  primary {
                    link_text
                    link {
                      url
                    }
                  }
                }
              }
              footer_social {
                ... on PrismicLayoutFooterSocialSocialItem {
                  primary {
                    platform
                    text
                    link {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [subscriberEmail, setSubscriberEmail] = useState(null);
  const [mailchimpResponse, setMailchimpResponse] = useState(null);

  const navItems = data.allPrismicLayout.edges[0].node.data.footer_menu;
  const socialItems = data.allPrismicLayout.edges[0].node.data.footer_social;
  const socialIcon = {
    linkedIn,
    twitter,
    email,
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(subscriberEmail);
    setMailchimpResponse(result);
  };

  return (
    <footer className="o-container-fluid" styleName="footer">
      <div styleName="footer__inner">
        <div styleName="footer__left">
          <img src={logo} alt="Argon Element Logo" styleName="footer__logo" />

          <div styleName="footer__copyright">
            <p className="u-text-medium">
              ©{new Date().getFullYear()} {siteTitle}
            </p>
            <p> All rights reserved</p>
            <ul>
              {navItems.map((item, i) => (
                <li key={i}>
                  <Link to={`${item.primary.link.url}`}>
                    {item.primary.link_text}
                  </Link>
                </li>
              ))}
            </ul>
            <div styleName="footer__craft">
              <img src={craftLogo} alt="Craft Logo" />
              <p>
                A collaboration with{' '}
                <a
                  href="https://www.madebycraft.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Craft
                </a>
              </p>
            </div>
          </div>
        </div>

        <div styleName="footer__center">
          <h3>Stay in Touch</h3>

          {!!mailchimpResponse ? (
            <div
              styleName="mailchimp__result"
              dangerouslySetInnerHTML={{ __html: mailchimpResponse.msg }}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Enter your email"
                onChange={e => setSubscriberEmail(e.target.value)}
              />
              <button
                type="submit"
                placeholder="Your email"
                disabled={subscriberEmail === null || subscriberEmail === ''}
              >
                <span>Subscribe</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M0,6 L12,6 M6,0 L12,6 L6,12"
                  />
                </svg>
              </button>
            </form>
          )}
        </div>

        <div styleName="footer__right">
          <h3>Connect with us</h3>
          <ul>
            {socialItems.map((item, i) => (
              <li key={i}>
                <a
                  href={`${item.primary.link.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      socialIcon[
                        item.primary.platform.charAt(0).toLowerCase() +
                          item.primary.platform.slice(1)
                      ]
                    }
                    alt="LinkedIn"
                  />
                  {item.primary.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
